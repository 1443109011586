import { rem } from 'polished'
import styled from 'styled-components'
import theme from 'styles/theme'
import { Spacing16, Spacing30, buttonShadow } from 'styles/sharedStyle'
import DefaultButton from 'components/Button'

export const LatestOffersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

export const LatestOffersTitle = styled.div`
  color: var(--Primary-Dark-Teal, #434341);
  text-align: center;
  font-family: SangbleuKindgdom-Light, serif;
  font-size: ${rem(34)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
`

export const LatestOffersFrameBoard = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: ${rem(597)};
  height: fit-content;
  flex-shrink: 0;
  background: var(--Primary-Pastel-Teal, #889494);
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15);
  ${theme.mediaQueries.mobile} {
    width: ${rem(368)};
    flex-shrink: 0;
  }
`

export const LatestOffersCardTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: SangbleuKindgdom-Light, serif;
  font-size: ${rem(34)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  transition: opacity 0.5s ease-in-out;
`

export const SpacingTitle = styled(Spacing16)`
  ${theme.mediaQueries.mobile} {
    height: 30px;
  }
`

export const SpacingDescription = styled(Spacing16)`
  ${theme.mediaQueries.mobile} {
    height: 20px;
  }
`

export const LatestOffersCardSubTitle = styled.div`
  color: #fff;
  text-align: center;
  font-family: SangbleuKindgdom-Light, serif;
  font-size: ${rem(20)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  transition: opacity 0.5s ease-in-out;
`

export const LatestOffersCardDescription = styled.div`
  color: #fff;
  text-align: center;
  font-family: BrownStd-Light, serif;
  font-size: ${rem(15)};
  font-style: normal;
  font-weight: 300;
  line-height: 180%;
  padding: 0 40px;
  transition: opacity 0.5s ease-in-out;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(12)};
    padding: 0 60px;
  }
`

export const LatestOffersFrame = styled.div`
  width: ${rem(583)};
  height: calc(100% - 14px);
  flex-shrink: 0;
  gap: 7px;
  background: ${theme.colors.teal};
  transition: opacity 0.5s ease-in-out;
  padding-bottom: ${rem(29)};
  border: 7px solid ${theme.colors.pastelTeal};
  box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.15),
    0px 0px 20px 0px rgba(0, 0, 0, 0.15);

  ${theme.mediaQueries.mobile} {
    width: ${rem(355)};
  }
`
export const SpacingHeader = styled(Spacing30)`
  ${theme.mediaQueries.mobile} {
    height: 39px;
  }
`

export const TitlesFrame = styled.div`
  ${theme.mediaQueries.mobile} {
    padding: 0 26.5px;
  }
`

export const LatestOffersDate = styled.div`
  color: #fff;
  text-align: center;
  font-family: BrownStd-Light, serif;
  font-size: ${rem(13.66)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  transition: opacity 0.5s ease-in-out;
`

export const CustomSpacing = styled.div`
  display: block;
  width: 100%;
  position: relative;
  height: ${rem(24)};
`

export const LatestOfferLinesWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  width: 100%;
  gap: ${rem(12)};
  ${theme.mediaQueries.mobile} {
    width: ${rem(300)};
  }
`

export const LineContainer = styled.div`
  position: relative;
  width: ${rem(285.25)};
  height: 1px;
`

export const LatestOfferLine1 = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ colorLine1 }) => colorLine1};
  position: relative;
  transition: background 0.5s ease-in-out;

  // Media query for mobile view
  ${theme.mediaQueries.mobile} {
    width: ${rem(147)};
    margin: 0 auto;
  }
`

export const LatestOfferLine2 = styled.div`
  width: 100%;
  height: 1px;
  background: ${({ colorLine2 }) => colorLine2};
  position: relative;
  transition: background 0.5s ease-in-out;

  // Media query for mobile view
  ${theme.mediaQueries.mobile} {
    width: ${rem(147)};
    margin: 0 auto;
  }
`

export const LatestOfferLine1Number = styled.div`
  position: absolute;
  top: -${rem(20)};
  left: 50%;
  font-style: italic;
  transform: translateX(-50%);
  font-size: ${rem(18)};
  color: ${({ colorLine1 }) => colorLine1};
  cursor: pointer;
  transition: color 0.5s ease-in-out;
`

export const LatestOfferLine2Number = styled.div`
  position: absolute;
  top: -${rem(20)};
  left: 50%;
  transform: translateX(-50%);
  font-style: italic;
  font-size: ${rem(18)};
  color: ${({ colorLine2 }) => colorLine2};
  cursor: pointer;
  transition: color 0.5s ease-in-out;
`

export const ButtonWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: ${rem(6.83)};
  justify-content: center;
  margin-top: ${rem(22)};

  ${theme.mediaQueries.small} {
    width: 100%;
  }

  ${theme.mediaQueries.mobile} {
    margin-top: ${rem(21)};
    flex-direction: column;
    gap: ${rem(6.83)};
  }
`

export const Button = styled(DefaultButton)`
  ${buttonShadow}
  display: flex;
  padding: 15.368px 0px 11.953px 70.862px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 127.852px;
  color: ${(props) => `#${props.backgroundColor}`};
  background: ${(props) => `#${props.color}`};
  text-decoration: none;
  font-size: ${rem(13.66)};

  ${theme.mediaQueries.small} {
    margin-right: 10px;
    margin-bottom: 0px;
  }

  span {
    line-height: initial;
    text-decoration: inherit;
    font-size: inherit;
  }

  ${theme.mediaQueries.medium} {
    padding: 1rem 3rem;
  }
  ${theme.mediaQueries.mobile} {
    justify-content: center;
    padding: 15.368px 0px 11.953px 0px;
    width: ${rem(170)};
    font-size: ${rem(13.66)};
  }
  transition: color 0.5s ease-in-out, background 0.5s ease-in-out;
`

export const PhantonButton = styled(DefaultButton)`
  display: flex;
  padding: 15.368px 0px 11.953px 70.862px;
  justify-content: flex-end;
  align-items: flex-start;
  gap: 127.852px;
  border-color: ${(props) => `#${props.color}`};
  color: ${(props) => `#${props.color}`};
  text-decoration: none;
  font-size: ${rem(13.66)};

  ${theme.mediaQueries.small} {
    margin-right: 10px;
    margin-bottom: 0px;
  }

  span {
    line-height: initial;
    text-decoration: inherit;
    font-size: inherit;
  }

  ${theme.mediaQueries.medium} {
    padding: ${rem(15.37)} ${rem(39.27)};
  }

  ${theme.mediaQueries.mobile} {
    justify-content: center;
    padding: 15.368px 0px 11.953px 0px;
    width: ${rem(170)};
  }
  transition: border-color 0.5s ease-in-out, color 0.5s ease-in-out;
`

export const CustomSpacingFooter = styled.div`
  display: block;
  width: 100%;
  position: relative;
  height: ${rem(26)};
`

export const LatestOffersCardLegalTerms = styled.div`
  color: #fff;
  text-align: center;
  font-family: BrownStd-Light, serif;
  font-size: ${rem(12)};
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  transition: opacity 0.5s ease-in-out;

  ${theme.mediaQueries.mobile} {
    font-size: ${rem(10)};
  }
`
